<template>
  <div class="or">
    <van-steps direction="vertical" :active="active" active-color="#0065FF">
      <van-step v-for="item in record" :key="item.id">
        <h3>
          {{ item.user_info.department_name }}-{{ item.user_info.name }} <span>{{ typeMap[item.opt_type] }}</span>
        </h3>
        <div class="reject_font">{{ item.log }}</div>
        <div class="time">
          <p class="time_p">{{ moment(Number(item.create_time) * 1000).format("HH:mm") }}</p>
          <p class="date_p">{{ moment(Number(item.create_time) * 1000).format("YY-MM-DD") }}</p>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
import { ref } from "vue";
import { storesManageService } from "@/service";
import { useRoute } from "vue-router";
import moment from "moment";
export default {
  setup() {
    const route = useRoute();
    const active = ref(0);
    const record = ref([]);

    const typeMap = {
      1: "提交开闭店申请",
      2: "通过开闭店申请",
      3: "驳回开闭店申请",
      4: "提交延期申请",
      5: "通过延期申请",
      6: "驳回延期申请",
      9: "系统自动通过"
    };

    const getRecord = async () => {
      const { data } = await storesManageService.ocOptLists({
        oc_id: route.query.id,
        page: 1,
        pagesize: 9999999
      });
      record.value = data.data;
      console.log(data, "=====");
    };
    getRecord();
    return { active, record, moment, typeMap };
  }
};
</script>

<style lang="less" scoped>
.or {
  padding-top: 15px;
  /deep/ .van-step__title h3 {
    margin: 0;
    font-size: 15px;
    color: #0065ff;
    line-height: 24px;
    span {
      font-size: 15px;
      color: #333333;
      line-height: 24px;
    }
  }
  /deep/ .van-steps--vertical {
    padding-left: 100px;
  }
  /deep/ .van-step--vertical {
    padding-bottom: 38px;
  }
  .reject_font {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5f6673;
    letter-spacing: 0;
    line-height: 24px;
  }
  .time {
    position: absolute;
    left: -90px;
    top: 10px;
    p {
      margin: 0;
    }
    .time_p {
      font-size: 18px;
      color: #333333;
      text-align: right;
      line-height: 24px;
    }
    .date_p {
      font-size: 12px;
      color: #959fac;
      text-align: right;
      line-height: 15px;
    }
  }
}
</style>
